import React from 'react'
import { separeDataByGroups } from './constants/separeDataByGroups'
import { FormattedMessage } from "react-intl";
import CertificateRow from './components/CertificateRow';
import "./styles/SustainabilityAttributesAccordion.scss"
import CertificateLink from '../OtherAttributesAccordion/components/CertificateLink';
import { 
  deleteGuionFromAttributes, 
  handleValidationBackingAttr, 
  handleValidationEpdAttr 
} from './constants/handleAttrValidations';

const SustainabilityAttributesAccordion = ({ data }) => {
  const { packagingFsc, sustainability, otherattributes,  manufacturingCertifications } = data
  const mainDataToShow = separeDataByGroups(sustainability, manufacturingCertifications, packagingFsc)

  return (
    <section>
      {
        mainDataToShow.map((group) => {
          return <React.Fragment key={`item-${ group.id }`}>
            <table className='border border-2 mt-2 w-100'>
              <tr>
                <td className='font-weight-bold' colSpan={4}>{group.title}</td>
              </tr>
              {
                group.attrs.map((attr) => {
                  let isEpdAttribute = handleValidationEpdAttr(attr);
                  let isBackingProfile = handleValidationBackingAttr(attr);
                  deleteGuionFromAttributes(attr);

                  return <tr key={`item__${attr.id}`}>
                    {!group.areCertificates && 
                      <td className='col-4'>
                        <FormattedMessage
                          id={`FiltersBar.${attr.title}FilterTitle`}
                        />
                      </td>
                    }

                    {!group.areCertificates &&
                      <td colSpan={2}>
                        {isBackingProfile && 
                          <img 
                            src={`${otherattributes.certificateDomain}${attr.logoImg}`} 
                            alt={attr.title}
                            width={80} 
                          />
                        }

                        {!isEpdAttribute && attr.value}

                        {
                          isEpdAttribute && 
                          attr.value !== "" && 
                          attr.value !== true &&
                          <CertificateLink link={`${otherattributes.certificateDomain}${attr.value}`} />
                        }
                      </td>
                    }

                    {!group.areCertificates && 
                      <td>
                        {!isEpdAttribute &&
                          attr.certificate !== "" &&
                          <CertificateLink link={`${otherattributes.certificateDomain}${attr.certificate}`} />
                        }
                      </td>
                    }
                  </tr>
                })
              }
              {group.areCertificates &&
                <td colSpan={4}>
                  <CertificateRow
                    groupTitle={group.title.key}
                    attrs={group.attrs}
                    link={otherattributes.certificateDomain}
                  />
                </td>}
            </table>
          </React.Fragment>
        })
      }
    </section>
  )
}

export default SustainabilityAttributesAccordion