import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  cleanDataForCertificates,
  translateLabel,
} from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow/utils";
import SpecsTableColumn from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow/components/SpecsTableColumn";
import SpecLogo from "utils/components/SpecLogo";
import { TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803 } from "scenes/ProductDetails/components/ProductSpecs/contants";
import { LOGO_PERMACOOL } from "utils/components/SpecLogo/constants/logos";
import "scenes/ProductDetails/components/ProductSpecs/components/GeneralAccordion/styles/general-accordion.scss";
import CertificateLink from "./components/CertificateLink";
import { calculateCertificateFileExpired } from "./constants";
import ExpirationCertificate from "./components/ExpirationCertificate";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "./constants/breakpoints";
import MobileInfoRows from "./components/MobileInfoRows";

const OtherAttributesAccordion = ({ data }) => {
  const isMobile = useMediaQuery(LG);
  const intl = useIntl();

  const { temperatureReductionTreatment, embossingSelect, ...restData } = data;
  const embossing = embossingSelect ? embossingSelect.name : null;
  const finalData = { ...restData, embossing };
  const bucket = finalData["certificateDomain"];
  delete finalData.certificateDomain;
  const matchedCertificateString = "CertificateFile";

  const cleanedData = cleanDataForCertificates(finalData);

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handletemperatureReductionTreatment = () => (
    <>
      <td>
        {intl.formatMessage({
          id: "ProductSpecAccordionBody.temperatureReductionTreatment",
        })}
      </td>
      {isJsonString(data.temperatureReductionTreatment) ? (
        <>
          <td className="d-flex align-items-center">
            <span>
              {JSON.parse(data.temperatureReductionTreatment).value ===
              TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803 ? (
                <SpecLogo spec={LOGO_PERMACOOL} />
              ) : (
                JSON.parse(data.temperatureReductionTreatment).value
              )}
            </span>
            <a
              className="technical_limitations ml-2"
              href={
                JSON.parse(data.temperatureReductionTreatment)
                  .technical_limitations
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle" />
            </a>
          </td>
        </>
      ) : (
        <>
          <td>{data.temperatureReductionTreatment}</td>
          <td>
            {data.temperatureReductionTreatmentCertificateFile && (
              <CertificateLink
                link={
                  data.certificateDomain +
                  data.temperatureReductionTreatmentCertificateFile
                }
              />
            )}
          </td>
        </>
      )}
    </>
  );

  return (
    <>
      {cleanedData.map((array, index) => {
        // Set new item for possible certificate and expired data
        array[2] = "";
        array[3] = "";
        
        if (array[0].indexOf("NotExpires") !== -1) return;
        if (array[0].indexOf(matchedCertificateString) !== -1) return;
        
        if (
          finalData.hasOwnProperty(`${array[0]}${matchedCertificateString}`) ||
          array[0].indexOf("Method") === -1
        ) {

          const keyWithoutMethod = `${array[0]}${matchedCertificateString}`;
          const keyWithMethod = `${array[0]}Method${matchedCertificateString}`;

          if (array[0].indexOf("Method") === -1) {
            if (finalData[keyWithoutMethod] !== undefined && finalData[keyWithoutMethod] !== "") {
                array[3] = data.certificateDomain + finalData[keyWithoutMethod];
            } else if (finalData[keyWithMethod] !== undefined && finalData[keyWithMethod] !== "") {
                array[3] = data.certificateDomain + finalData[keyWithMethod];
            }
          }
        }

        // Validation to calculate expires certificates
        if (array[3] !== "") {
          const expiresValue = finalData[`${array[0]}${matchedCertificateString}Expires`]
          const notExpiresValue = finalData[`${array[0]}NotExpires`]
          
          const resultExpiresValue = calculateCertificateFileExpired(expiresValue, notExpiresValue);
          array[2] = <ExpirationCertificate expiredData={resultExpiresValue} onlyCircleWithoutText={isMobile} />
        }

        // Translate item title
        array[0] = translateLabel(array[0], intl);
        return !isMobile ? (
            <tr key={index}>
              {array.map((item, index) => {
                return <td key={index}>
                  {index === 3 ? (
                    <SpecsTableColumn value={<CertificateLink link={item} />} />
                  ) : (
                    <SpecsTableColumn value={item} />
                  )}
                </td>
              })}
            </tr>
          ) : <MobileInfoRows currentItemInfo={array} />
      })}
      {data.temperatureReductionTreatment && (
        <tr>{handletemperatureReductionTreatment()}</tr>
      )}
    </>
  );
};

OtherAttributesAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      technical_limitations: PropTypes.string,
    })
  ).isRequired,
};

export default OtherAttributesAccordion;
